import React from "react";
import styled from "styled-components";

const WrappedLi = styled.li`
  border-left: 1px solid black;
  border-top: 1px solid black;
  display: inline-block;
  padding: 15px;
  width: 400px;
  height: 200px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const WrappedTitle = styled.h4`
  margin-top: 15px;
`;

const WrappedDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
const WrappedA = styled.a`
  text-decoration: none;
  color: black;
  font-family: Raleway, sans-serif;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  background-image: none;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }
`;

const WrappedP = styled.p`
  height: 80px;
`;
export default ({ project }) => {
  return (
    <WrappedLi>
      <WrappedTitle>{project.name}</WrappedTitle>
      <WrappedP>{project.description}</WrappedP>
      <WrappedDiv>
        <WrappedA href={project.url}>Visit</WrappedA>
      </WrappedDiv>
    </WrappedLi>
  );
};

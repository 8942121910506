import React from "react";
import ContentBlock from "../components/Content/ContentBlock";
import PageTitle from "../components/PageTitle/PageTitle";
import ProjectList from "../components/ProjectList/ProjectList";
import projects from "../../content/projects.json";

export default () => {
  return (
    <div>
      <PageTitle title="Projects" url="http://alexdhaenens.com/projects" />
      <ContentBlock title="Personal projects">
        <ProjectList projects={projects.personal} />
      </ContentBlock>
      <ContentBlock title="Professional projects">
        <ProjectList projects={projects.professional} />
      </ContentBlock>
    </div>
  );
};

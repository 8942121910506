import React from "react";
import ProjectListItem from "./ProjectListItem";
import styled from "styled-components";

const WrappedUl = styled.ul`
  list-style: none;
  margin: 0px;
`;
export default ({ projects }) => {
  return (
    <WrappedUl>
      {projects.map(p => (
        <ProjectListItem project={p} />
      ))}
    </WrappedUl>
  );
};
